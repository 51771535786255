module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","lang":"el","name":"S-Team Ο.Ε.","short_name":"S-Team","description":"ΜΗΧΑΝΟΓΡΑΦΗΣΗ & ΜΗΧΑΝΟΡΓΑΝΩΣΗ","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"./src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5e81c94fd623730756da0c69c3834035"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
