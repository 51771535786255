/* Gatsby Browser API
 * -------------------
 * The file gatsby-browser.js lets you respond to Gatsby-specific events within
 * the browser, and wrap your page components in additional global components.
 * The Gatsby Browser API gives you many options for interacting with the
 * client-side of Gatsby.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import { PrismicProvider } from "@prismicio/react";
import React from "react";
import { ThemeProvider } from "./src/context/ThemeContext";
import { Link } from "gatsby";

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider pageData={props.data}>
      <PrismicProvider
        internalLinkComponent={({ href, ...props }) => (
          <Link to={href} {...props} />
        )}
      >
        {element}
      </PrismicProvider>
    </ThemeProvider>
  );
};
// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   //const currentPosition = getSavedScrollPosition(location);
//   //const queriedPosition = getSavedScrollPosition({ pathname: `/random` });

//   window.scrollTo([0, 0]);

//   return false;
// };
