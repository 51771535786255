import React, { createContext, useContext, useMemo } from "react";

const ThemeContext = createContext({
  settings: {},
  page: {},
});

const ThemeProvider = ({ children, pageData }) => {
  const { settings, page } = pageData;

  const value = useMemo(() => ({ settings, page }), [settings, page]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error("useTheme must be used within ThemeProvider");
  }

  return context;
};

export { ThemeContext, ThemeProvider, useTheme };
